import React from 'react';

import ContentBreadcrumb from '../components/content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../components/content/ContentHeader/ContentHeader';
import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';
import ContactForm from '../components/webform/ContactForm';

const ContactPage = ({ pageContext }) => {
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <Seo title="Contact TEGOVA" />
            <ContentBreadcrumb crumbs={crumbs} label="Contact TEGOVA" />
            <ContentHeader title="Contact TEGOVA" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <ContactForm />
                        <div
                            className="spinner-border text-primary"
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactPage;

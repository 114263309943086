import { graphql, navigate, useStaticQuery } from 'gatsby';
import Webform from 'gatsby-drupal-webform';
import React from 'react';

export default function ContactForm() {
    const { webform } = useStaticQuery(
        graphql`
            query {
                webform: webformWebform(
                    drupal_internal__id: { eq: "contact" }
                ) {
                    drupal_internal__id
                    elements {
                        name
                        type
                        attributes {
                            name
                            value
                        }
                    }
                }
            }
        `,
    );

    if (!webform) {
        return <></>;
    }

    return (
        <Webform
            webform={webform}
            endpoint={
                process.env.GATSBY_API_URL + '/react_webform_backend/submit'
            }
            onSuccess={(response) =>
                navigate(response.settings.confirmation_url)
            }
        />
    );
}
